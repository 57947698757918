html,
body {
  text-align: left;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 1.5rem 10rem;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  color: inherit;
  transition: all 0.2s;
  text-decoration: none;
  font-weight: 500;
}

.homeImage {
      width: 800px;
      height: auto;
} 

img {
  max-height: 40rem;
  max-width: 90vw;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.02);
  margin-right: 2vw;
  margin-top: 2vh;
  border-radius: 15px;
}

.card {
  border-radius: 15px;

  min-width: 50rem;
  min-height: 10rem;
  flex-direction: column;
}

.cardText {
  width: 70%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5h;
}

.outline {
  margin-top: 4rem;
  word-wrap: break-word;
}

.section {
  margin: 5 auto;
}

.title {
  margin-top: 7vh;
  max-width: 50rem;
}

.pill {
  border: 1px solid;
  border-color: inherit;
  border-radius: 50px;
  padding: 6px;
  white-space: nowrap;
  display: inline-block;
  font-size: 0.6rem;
  align-self: flex-end;
}

.pill2 {
  border: 1px solid;
  border-color: inherit;
  border-radius: 50px;
  padding: 10px;
  white-space: nowrap;
  display: inline-block;
  font-size: 0.7rem;
  align-self: flex-end;
  margin-right: 0.5rem;
  margin-top: 0.05rem;
}

.year {
  margin-top: 5vh;
}

.green {
  color: #5edd74;
}

.link {
  background-color: var(--blur-bg);
  border-radius: 50px;
  padding: 1rem;
  margin: 0.2rem;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(9.4px);
  white-space: nowrap;
}

.link:hover {
  opacity: 0.8;
  cursor: pointer;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

h3 {
  font-size: 1.25rem;
  font-weight: 400;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

h5 {
  font-size: 0.75rem;
  font-weight: 400;
}

p {
  font-size: 1rem;
  font-weight: 300;
}

@media (prefers-color-scheme: dark) {
  :root {
    --dark: #f0f0f0;
    --white: rgb(44, 42, 42);
    --blur-bg: hsla(183, 54%, 73%, 0.05);
  }

  body {
    background-color: var(--dark);
    color: var(--white);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --dark: #efeeee;
    --white: #1e1d1d;
    --blur-bg: rgba(170, 196, 200, 0.15);
  }

  body {
    background-color: var(--white);
    color: var(--dark);
  }
}

@media only screen and (orientation: portrait) {
  .title {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .cardText {
    width: 70%;
  }
}
